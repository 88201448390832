<template>
  <div class="best-designs">
    <h2 class="title">{{ $t('home.merch.sector2.title') }}</h2>
    <div class="btn-list">
      <nuxt-link :to="$trailingSlash(localePath(links[0]))" class="btn">
        <span>{{ $t('home.merch.sector2.subtitle') }}</span>
      </nuxt-link>
      <nuxt-link :to="$trailingSlash(localePath(links[1]))" class="btn">
        <span>{{ $t('home.merch.sector2.subtitle2') }}</span>
      </nuxt-link>
      <nuxt-link :to="$trailingSlash(localePath(links[2]))" class="btn">
        <span>{{ $t('home.merch.sector2.subtitle3') }}</span>
      </nuxt-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FindBestDesignsBlocks',
  props: {
    links: {
      type: Array,
      default: () => ['merch-trending-designs', 'our-graphics', 'categories']
    }
  }
};
</script>

<style lang="scss" scoped>
.best-designs {
  width: 100%;
}
.best-designs > .title {
  color: #a4a4a4;
  font-size: 21px;
  font-weight: 400;
}
.best-designs > .btn-list {
  display: flex;
  width: calc(100% + 10px);
  margin-left: -5px;
  @include lg {
    flex-flow: wrap;
  }
  @include md {
    width: 100%;
    margin: 0;
  }
}
.best-designs > .btn-list > .btn {
  width: calc(33.33% - 10px);
  margin: 5px;
  background-size: 100% auto;
  background-position: center;
  padding: 10px;
  min-height: 80px;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-weight: 500;
  transition: background-size 0.5s;
  @include lg {
    width: calc(50% - 10px);
  }
  @include md {
    width: 100%;
    margin: 5px auto;
  }
}
.best-designs > .btn-list > .btn:hover {
  background-size: 120% auto;
}
.best-designs > .btn-list > .btn:first-child {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url('https://cdn.vexels.com/img/hosttest.png');
}
.best-designs > .btn-list > .btn:nth-child(2) {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url('https://cdn.vexels.com/img/latest.png');
}
.best-designs > .btn-list > .btn:last-child {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url('https://cdn.vexels.com/img/designs-for-you.png');
  @include lg {
    margin: 5px auto;
  }
}
.best-designs > .btn-list > .btn > span {
  margin: auto;
}
</style>
