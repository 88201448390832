<template>
  <div class="best-categories">
    <h2 class="title">{{ $t('404.main.categories.title') }}</h2>
    <div class="categories-container">
      <div class="categories-column">
        <ul>
          <li>
            <nuxt-link
              :to="
                $urlBuilder('search', {
                  path: 'graphics',
                  keyword: $t('404.main.categories.keyword1')
                })
              "
            >
              {{ $t('404.main.categories.item1') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="
                $urlBuilder('search', {
                  path: 'graphics',
                  keyword: $t('404.main.categories.keyword2')
                })
              "
            >
              {{ $t('404.main.categories.item2') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="
                $urlBuilder('search', {
                  path: 'graphics',
                  keyword: $t('404.main.categories.keyword3')
                })
              "
            >
              {{ $t('404.main.categories.item3') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="
                $urlBuilder('search', {
                  path: 'graphics',
                  keyword: $t('404.main.categories.keyword4')
                })
              "
            >
              {{ $t('404.main.categories.item4') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="
                $urlBuilder('search', {
                  path: 'graphics',
                  keyword: $t('404.main.categories.keyword5')
                })
              "
            >
              {{ $t('404.main.categories.item5') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="
                $urlBuilder('search', {
                  path: 'graphics',
                  keyword: $t('404.main.categories.keyword6')
                })
              "
            >
              {{ $t('404.main.categories.item6') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="
                $urlBuilder('search', {
                  path: 'graphics',
                  keyword: $t('404.main.categories.keyword7')
                })
              "
            >
              {{ $t('404.main.categories.item7') }}
            </nuxt-link>
          </li>
        </ul>
      </div>
      <div class="categories-column">
        <ul>
          <li>
            <nuxt-link
              :to="
                $urlBuilder('search', {
                  path: 'graphics',
                  keyword: $t('404.main.categories.keyword8')
                })
              "
            >
              {{ $t('404.main.categories.item8') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="
                $urlBuilder('search', {
                  path: 'graphics',
                  keyword: $t('404.main.categories.keyword9')
                })
              "
            >
              {{ $t('404.main.categories.item9') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="
                $urlBuilder('search', {
                  path: 'graphics',
                  keyword: $t('404.main.categories.keyword10')
                })
              "
            >
              {{ $t('404.main.categories.item10') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="
                $urlBuilder('search', {
                  path: 'graphics',
                  keyword: $t('404.main.categories.keyword11')
                })
              "
            >
              {{ $t('404.main.categories.item11') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="
                $urlBuilder('search', {
                  path: 'graphics',
                  keyword: $t('404.main.categories.keyword12')
                })
              "
            >
              {{ $t('404.main.categories.item12') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="
                $urlBuilder('search', {
                  path: 'graphics',
                  keyword: $t('404.main.categories.keyword13')
                })
              "
            >
              {{ $t('404.main.categories.item13') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="
                $urlBuilder('search', {
                  path: 'graphics',
                  keyword: $t('404.main.categories.keyword14')
                })
              "
            >
              {{ $t('404.main.categories.item14') }}
            </nuxt-link>
          </li>
        </ul>
      </div>
      <div class="categories-column">
        <ul>
          <li>
            <nuxt-link
              :to="
                $urlBuilder('search', {
                  path: 'graphics',
                  keyword: $t('404.main.categories.keyword15')
                })
              "
            >
              {{ $t('404.main.categories.item15') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="
                $urlBuilder('search', {
                  path: 'graphics',
                  keyword: $t('404.main.categories.keyword16')
                })
              "
            >
              {{ $t('404.main.categories.item16') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="
                $urlBuilder('search', {
                  path: 'graphics',
                  keyword: $t('404.main.categories.keyword17')
                })
              "
            >
              {{ $t('404.main.categories.item17') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="
                $urlBuilder('search', {
                  path: 'graphics',
                  keyword: $t('404.main.categories.keyword18')
                })
              "
            >
              {{ $t('404.main.categories.item18') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="
                $urlBuilder('search', {
                  path: 'graphics',
                  keyword: $t('404.main.categories.keyword19')
                })
              "
            >
              {{ $t('404.main.categories.item19') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="
                $urlBuilder('search', {
                  path: 'graphics',
                  keyword: $t('404.main.categories.keyword20')
                })
              "
            >
              {{ $t('404.main.categories.item20') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="
                $urlBuilder('search', {
                  path: 'graphics',
                  keyword: $t('404.main.categories.keyword21')
                })
              "
            >
              {{ $t('404.main.categories.item21') }}
            </nuxt-link>
          </li>
        </ul>
      </div>
      <div class="categories-column">
        <ul>
          <li>
            <nuxt-link
              :to="
                $urlBuilder('search', {
                  path: 'graphics',
                  keyword: $t('404.main.categories.keyword22')
                })
              "
            >
              {{ $t('404.main.categories.item22') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="
                $urlBuilder('search', {
                  path: 'graphics',
                  keyword: $t('404.main.categories.keyword23')
                })
              "
            >
              {{ $t('404.main.categories.item23') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="
                $urlBuilder('search', {
                  path: 'graphics',
                  keyword: $t('404.main.categories.keyword24')
                })
              "
            >
              {{ $t('404.main.categories.item24') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="
                $urlBuilder('search', {
                  path: 'graphics',
                  keyword: $t('404.main.categories.keyword25')
                })
              "
            >
              {{ $t('404.main.categories.item25') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="
                $urlBuilder('search', {
                  path: 'graphics',
                  keyword: $t('404.main.categories.keyword26')
                })
              "
            >
              {{ $t('404.main.categories.item26') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="
                $urlBuilder('search', {
                  path: 'graphics',
                  keyword: $t('404.main.categories.keyword27')
                })
              "
            >
              {{ $t('404.main.categories.item27') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="
                $urlBuilder('search', {
                  path: 'graphics',
                  keyword: $t('404.main.categories.keyword28')
                })
              "
            >
              {{ $t('404.main.categories.item28') }}
            </nuxt-link>
          </li>
        </ul>
      </div>
      <div class="categories-column">
        <ul>
          <li>
            <nuxt-link
              :to="
                $urlBuilder('search', {
                  path: 'graphics',
                  keyword: $t('404.main.categories.keyword29')
                })
              "
            >
              {{ $t('404.main.categories.item29') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="
                $urlBuilder('search', {
                  path: 'graphics',
                  keyword: $t('404.main.categories.keyword30')
                })
              "
            >
              {{ $t('404.main.categories.item30') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="
                $urlBuilder('search', {
                  path: 'graphics',
                  keyword: $t('404.main.categories.keyword31')
                })
              "
            >
              {{ $t('404.main.categories.item31') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="
                $urlBuilder('search', {
                  path: 'graphics',
                  keyword: $t('404.main.categories.keyword32')
                })
              "
            >
              {{ $t('404.main.categories.item32') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="
                $urlBuilder('search', {
                  path: 'graphics',
                  keyword: $t('404.main.categories.keyword33')
                })
              "
            >
              {{ $t('404.main.categories.item33') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="
                $urlBuilder('search', {
                  path: 'graphics',
                  keyword: $t('404.main.categories.keyword34')
                })
              "
            >
              {{ $t('404.main.categories.item34') }}
            </nuxt-link>
          </li>
          <li>
            <nuxt-link
              :to="
                $urlBuilder('search', {
                  path: 'graphics',
                  keyword: $t('404.main.categories.keyword35')
                })
              "
            >
              {{ $t('404.main.categories.item35') }}
            </nuxt-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BestCategories'
};
</script>

<style lang="scss" scoped>
.best-categories {
  width: 100%;
  margin-top: 70px;
  > .title {
    color: #a4a4a4;
    font-size: 21px;
  }
  > .categories-container {
    display: flex;
    margin-top: 20px;
    @include md {
      flex-flow: wrap;
    }
    @include xs {
      display: block;
    }
    > .categories-column {
      width: 20%;
      @include md {
        width: 50%;
      }
      @include xs {
        width: 100%;
      }
      > ul {
        list-style: none;
        padding: 0;
        font-size: 12px;
        padding-right: 10px;
        > li {
          margin: 7px 0;
          > a {
            color: #4d4d4d;
          }
        }
      }
    }
  }
}
</style>
