<template>
  <b-row align-h="center" class="w-100 m-auto">
    <b-col md="10" lg="8" xl="6" class="mb-4">
      <div class="noresult align-items-center justify-content-between">
        <div class="img-container">
          <Picture
            :image-sizes="imageSizes"
            :alt="$t('404.main.content.title')"
            url="https://cdn.vexels.com/img/error-404.png"
          />
        </div>
        <div class="text-container">
          <h2 class="title">{{ $t('404.main.content.title') }}</h2>
          <p>
            {{ $t('404.main.content.descriptionTextBefore') }}
            <span class="contact-us-link cursor-pointer" @click="$bvModal.show('contact-modal')">
              {{ $t('404.main.content.link') }}
            </span>
            {{ $t('404.main.content.descriptionTextAfter') }}
          </p>
        </div>
      </div>

      <FindBestDesignsBlocks />
      <BestCategories />
    </b-col>
  </b-row>
</template>

<script>
import FindBestDesignsBlocks from '@/components/Common/FindBestDesignsBlocks';
import BestCategories from '@/components/Common/BestCategories';
import Picture from 'UI/Picture';

export default {
  components: {
    FindBestDesignsBlocks,
    BestCategories,
    Picture
  },
  data() {
    return {
      imageSizes: [
        { resolution: 768, size: 350 },
        { resolution: 576, size: 767 },
        { resolution: 375, size: 413 },
        { resolution: 0, size: 374 }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.noresult {
  margin: 70px auto 70px auto;
  transition: 0.5s width;
  display: flex;
  @include md {
    display: block;
    text-align: center;
  }
  .img-container {
    width: 45%;
    padding: 0 20px;
    @include xl {
      width: 50%;
    }
    @include md {
      width: 100%;
      padding: 0;
    }
  }
  .text-container {
    width: 45%;
    color: #a2a2a2;
    padding: 0 20px;
    @include xl {
      width: 50%;
    }
    @include md {
      width: 100%;
      padding: 0;
      margin: auto;
      margin-top: 25px;
      text-align: left;
    }
    > .title {
      margin-bottom: 20px;
      font-size: 45px;
      @include md {
        font-size: 35px;
      }
    }
    > p {
      font-size: 15px;
      line-height: 30px;
      color: #a2a2a2;
      > .contact-us-link {
        color: #a2a2a2;
        text-decoration: underline;
      }
    }
  }
}
.black-color {
  color: #000000;
}
</style>
