var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"best-categories"},[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('404.main.categories.title')))]),_vm._v(" "),_c('div',{staticClass:"categories-container"},[_c('div',{staticClass:"categories-column"},[_c('ul',[_c('li',[_c('nuxt-link',{attrs:{"to":_vm.$urlBuilder('search', {
                path: 'graphics',
                keyword: _vm.$t('404.main.categories.keyword1')
              })}},[_vm._v("\n            "+_vm._s(_vm.$t('404.main.categories.item1'))+"\n          ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.$urlBuilder('search', {
                path: 'graphics',
                keyword: _vm.$t('404.main.categories.keyword2')
              })}},[_vm._v("\n            "+_vm._s(_vm.$t('404.main.categories.item2'))+"\n          ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.$urlBuilder('search', {
                path: 'graphics',
                keyword: _vm.$t('404.main.categories.keyword3')
              })}},[_vm._v("\n            "+_vm._s(_vm.$t('404.main.categories.item3'))+"\n          ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.$urlBuilder('search', {
                path: 'graphics',
                keyword: _vm.$t('404.main.categories.keyword4')
              })}},[_vm._v("\n            "+_vm._s(_vm.$t('404.main.categories.item4'))+"\n          ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.$urlBuilder('search', {
                path: 'graphics',
                keyword: _vm.$t('404.main.categories.keyword5')
              })}},[_vm._v("\n            "+_vm._s(_vm.$t('404.main.categories.item5'))+"\n          ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.$urlBuilder('search', {
                path: 'graphics',
                keyword: _vm.$t('404.main.categories.keyword6')
              })}},[_vm._v("\n            "+_vm._s(_vm.$t('404.main.categories.item6'))+"\n          ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.$urlBuilder('search', {
                path: 'graphics',
                keyword: _vm.$t('404.main.categories.keyword7')
              })}},[_vm._v("\n            "+_vm._s(_vm.$t('404.main.categories.item7'))+"\n          ")])],1)])]),_vm._v(" "),_c('div',{staticClass:"categories-column"},[_c('ul',[_c('li',[_c('nuxt-link',{attrs:{"to":_vm.$urlBuilder('search', {
                path: 'graphics',
                keyword: _vm.$t('404.main.categories.keyword8')
              })}},[_vm._v("\n            "+_vm._s(_vm.$t('404.main.categories.item8'))+"\n          ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.$urlBuilder('search', {
                path: 'graphics',
                keyword: _vm.$t('404.main.categories.keyword9')
              })}},[_vm._v("\n            "+_vm._s(_vm.$t('404.main.categories.item9'))+"\n          ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.$urlBuilder('search', {
                path: 'graphics',
                keyword: _vm.$t('404.main.categories.keyword10')
              })}},[_vm._v("\n            "+_vm._s(_vm.$t('404.main.categories.item10'))+"\n          ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.$urlBuilder('search', {
                path: 'graphics',
                keyword: _vm.$t('404.main.categories.keyword11')
              })}},[_vm._v("\n            "+_vm._s(_vm.$t('404.main.categories.item11'))+"\n          ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.$urlBuilder('search', {
                path: 'graphics',
                keyword: _vm.$t('404.main.categories.keyword12')
              })}},[_vm._v("\n            "+_vm._s(_vm.$t('404.main.categories.item12'))+"\n          ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.$urlBuilder('search', {
                path: 'graphics',
                keyword: _vm.$t('404.main.categories.keyword13')
              })}},[_vm._v("\n            "+_vm._s(_vm.$t('404.main.categories.item13'))+"\n          ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.$urlBuilder('search', {
                path: 'graphics',
                keyword: _vm.$t('404.main.categories.keyword14')
              })}},[_vm._v("\n            "+_vm._s(_vm.$t('404.main.categories.item14'))+"\n          ")])],1)])]),_vm._v(" "),_c('div',{staticClass:"categories-column"},[_c('ul',[_c('li',[_c('nuxt-link',{attrs:{"to":_vm.$urlBuilder('search', {
                path: 'graphics',
                keyword: _vm.$t('404.main.categories.keyword15')
              })}},[_vm._v("\n            "+_vm._s(_vm.$t('404.main.categories.item15'))+"\n          ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.$urlBuilder('search', {
                path: 'graphics',
                keyword: _vm.$t('404.main.categories.keyword16')
              })}},[_vm._v("\n            "+_vm._s(_vm.$t('404.main.categories.item16'))+"\n          ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.$urlBuilder('search', {
                path: 'graphics',
                keyword: _vm.$t('404.main.categories.keyword17')
              })}},[_vm._v("\n            "+_vm._s(_vm.$t('404.main.categories.item17'))+"\n          ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.$urlBuilder('search', {
                path: 'graphics',
                keyword: _vm.$t('404.main.categories.keyword18')
              })}},[_vm._v("\n            "+_vm._s(_vm.$t('404.main.categories.item18'))+"\n          ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.$urlBuilder('search', {
                path: 'graphics',
                keyword: _vm.$t('404.main.categories.keyword19')
              })}},[_vm._v("\n            "+_vm._s(_vm.$t('404.main.categories.item19'))+"\n          ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.$urlBuilder('search', {
                path: 'graphics',
                keyword: _vm.$t('404.main.categories.keyword20')
              })}},[_vm._v("\n            "+_vm._s(_vm.$t('404.main.categories.item20'))+"\n          ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.$urlBuilder('search', {
                path: 'graphics',
                keyword: _vm.$t('404.main.categories.keyword21')
              })}},[_vm._v("\n            "+_vm._s(_vm.$t('404.main.categories.item21'))+"\n          ")])],1)])]),_vm._v(" "),_c('div',{staticClass:"categories-column"},[_c('ul',[_c('li',[_c('nuxt-link',{attrs:{"to":_vm.$urlBuilder('search', {
                path: 'graphics',
                keyword: _vm.$t('404.main.categories.keyword22')
              })}},[_vm._v("\n            "+_vm._s(_vm.$t('404.main.categories.item22'))+"\n          ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.$urlBuilder('search', {
                path: 'graphics',
                keyword: _vm.$t('404.main.categories.keyword23')
              })}},[_vm._v("\n            "+_vm._s(_vm.$t('404.main.categories.item23'))+"\n          ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.$urlBuilder('search', {
                path: 'graphics',
                keyword: _vm.$t('404.main.categories.keyword24')
              })}},[_vm._v("\n            "+_vm._s(_vm.$t('404.main.categories.item24'))+"\n          ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.$urlBuilder('search', {
                path: 'graphics',
                keyword: _vm.$t('404.main.categories.keyword25')
              })}},[_vm._v("\n            "+_vm._s(_vm.$t('404.main.categories.item25'))+"\n          ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.$urlBuilder('search', {
                path: 'graphics',
                keyword: _vm.$t('404.main.categories.keyword26')
              })}},[_vm._v("\n            "+_vm._s(_vm.$t('404.main.categories.item26'))+"\n          ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.$urlBuilder('search', {
                path: 'graphics',
                keyword: _vm.$t('404.main.categories.keyword27')
              })}},[_vm._v("\n            "+_vm._s(_vm.$t('404.main.categories.item27'))+"\n          ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.$urlBuilder('search', {
                path: 'graphics',
                keyword: _vm.$t('404.main.categories.keyword28')
              })}},[_vm._v("\n            "+_vm._s(_vm.$t('404.main.categories.item28'))+"\n          ")])],1)])]),_vm._v(" "),_c('div',{staticClass:"categories-column"},[_c('ul',[_c('li',[_c('nuxt-link',{attrs:{"to":_vm.$urlBuilder('search', {
                path: 'graphics',
                keyword: _vm.$t('404.main.categories.keyword29')
              })}},[_vm._v("\n            "+_vm._s(_vm.$t('404.main.categories.item29'))+"\n          ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.$urlBuilder('search', {
                path: 'graphics',
                keyword: _vm.$t('404.main.categories.keyword30')
              })}},[_vm._v("\n            "+_vm._s(_vm.$t('404.main.categories.item30'))+"\n          ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.$urlBuilder('search', {
                path: 'graphics',
                keyword: _vm.$t('404.main.categories.keyword31')
              })}},[_vm._v("\n            "+_vm._s(_vm.$t('404.main.categories.item31'))+"\n          ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.$urlBuilder('search', {
                path: 'graphics',
                keyword: _vm.$t('404.main.categories.keyword32')
              })}},[_vm._v("\n            "+_vm._s(_vm.$t('404.main.categories.item32'))+"\n          ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.$urlBuilder('search', {
                path: 'graphics',
                keyword: _vm.$t('404.main.categories.keyword33')
              })}},[_vm._v("\n            "+_vm._s(_vm.$t('404.main.categories.item33'))+"\n          ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.$urlBuilder('search', {
                path: 'graphics',
                keyword: _vm.$t('404.main.categories.keyword34')
              })}},[_vm._v("\n            "+_vm._s(_vm.$t('404.main.categories.item34'))+"\n          ")])],1),_vm._v(" "),_c('li',[_c('nuxt-link',{attrs:{"to":_vm.$urlBuilder('search', {
                path: 'graphics',
                keyword: _vm.$t('404.main.categories.keyword35')
              })}},[_vm._v("\n            "+_vm._s(_vm.$t('404.main.categories.item35'))+"\n          ")])],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }