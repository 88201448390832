<template>
  <picture>
    <source
      v-for="{ resolution, size } in imageSizes"
      :key="resolution"
      :media="`(min-width: ${resolution}px)`"
      :srcset="`${url}${url.includes('?') ? '&' : '?'}w=${size}&fmt=webp`"
    />
    <img :height="height" :width="width" :src="url" :alt="alt" loading="lazy" />
  </picture>
</template>

<script>
export default {
  props: {
    imageSizes: {
      type: Array,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      required: true
    },
    width: {
      type: Number,
      default: 300
    },
    height: {
      type: Number,
      default: 300
    }
  }
};
</script>
<style lang="scss" scoped>
img {
  height: auto;
  width: 100%;
}
</style>
